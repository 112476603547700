import * as React from 'react'
import * as Base from 'react-festival/build/menu'
import Manifest from '@festival/manifest'
import { Store as EditionStore } from '@festival/edition/store'
import { inject } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import {
    Collapse,
    Navbar, NavbarToggler, Nav, NavItem,
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { RouteManager } from '@festival/route-manager'
import Advertisement from '@festival/page-configuration/advertisement'
import PartnerList from '@festival/partner/list'
import Side from './side'
import { format, addDays } from 'date-fns'
import fr from 'date-fns/locale/fr'

export interface Props extends Base.Props {
    manifest?: Manifest
    editionStore?: EditionStore
}

export class Menu extends Base.Menu<Props> {

    protected renderBrand (): React.ReactNode {
        const { manifest, editionStore } = this.props

        if (!manifest || !editionStore) {
            return null
        }

        process.env.TZ = 'Europe/Paris'
        const end = addDays(editionStore.current.referenceDate, editionStore.current.nbDays - 1)
        const dates = [
            format(editionStore.current.referenceDate, 'd', { locale: fr }),
            '-',
            format(end, 'd', { locale: fr }),
            ' ',
            format(editionStore.current.referenceDate, 'MMMM yyyy', { locale: fr })
        ].join('')

        return <>
            <img src={manifest.get('img/logo.png')} className="pl-2 h-100 d-none d-md-block" alt="Logo"/>
            <img src={manifest.get('img/hashtag-festival.png')} className="pl-2 h-100" alt="Hashtag-festival"/>
            <img src={manifest.get('img/logo-banniere.png')} className="pl-2 h-100 d-none d-sm-block" alt="Mascotte"/>
            <img src={manifest.get('img/dates-' + editionStore.current.name + '.png')} className="pl-2 h-100" alt={dates}/>
        </>
    }

    protected renderNav (): React.ReactNode {
        const nav = super.renderNav()

        const { routeManager } = this.props

        if (!routeManager) {
            return null
        }

        return <>
            { nav }

            <Side className="d-md-none bg-white p-2 border border-primary" />
        </>
    }

    protected get defaultColor (): string {
        return 'primary'
    }

    protected get defaultLight (): boolean {
        return false
    }

    protected get defaultDark (): boolean {
        return true
    }

    protected get defaultExpand (): string {
        return 'md'
    }

    protected get className (): string {
        return 'w-100 p-0 d-flex justify-content-around flex-row flex-md-column flex-wrap'
    }

    protected get brandClass (): string {
        return 'position-relative d-flex align-items-center p-2 mr-0'
    }

    protected get navClass (): string {
        return 'w-100 d-flex justify-content-around flex-row flex-wrap bg-primary'
    }

    protected renderItem (key: number, link: Base.Link): React.ReactNode {
        return (
            <NavItem key={key}>
                <NavLink
                    to={link.to}
                    className="nav-link"
                    onClick={() => this.setState({isOpen: false})}
                    >
                    <FormattedMessage id={link.label} />
                </NavLink>
            </NavItem>
        )
    }
}

export default inject('routeManager', 'manifest', 'editionStore')(Menu)
