import * as React from 'react'
import { inject, observer } from 'mobx-react'
import * as Base from 'react-festival/build/layout'
import { FormattedMessage } from 'react-intl'
import {
    Container, Row, Col,
    Navbar, Nav, NavItem,
} from 'reactstrap'
import { Switch, Route, NavLink, Link } from 'react-router-dom'
import Advertisement from '@festival/page-configuration/advertisement'
import Header from '@festival/page-configuration/header'
import PartnerList from '@festival/partner/list'
import Menu from '@festival/menu'
import Side from './side'

export class Layout extends Base.Layout {
    renderContent (): React.ReactNode {
        const { routeManager } = this.props

        if (!routeManager) {
            return null
        }

        return (
            <Container fluid="lg" className="min-vh-100 p-0 d-flex flex-column pb-3">
                <Menu />

                <div className="flex-fill bg-white p-3">
                    <Header />

                    <Row>
                        <Col md={3} className="d-none d-md-block">
                            <Side />
                        </Col>
                        <Col>
                            <Switch>
                                { this.renderRoutes() }
                            </Switch>
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    }
}

export default inject('routeManager', 'pageConfigurationStore', 'endpointStore')(Layout)
