import * as React from 'react'
import { Dialog as Base, Store } from 'react-mobx-cookie-consent'
import { UnregisterCallback } from 'history'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { GA } from './ga'

export interface Props extends RouteComponentProps<any> {
    ga: GA
}

export interface State {

}

export class Tracker extends React.Component<Props, State> {
    protected unlisten: UnregisterCallback | undefined


    componentDidMount(): void {
        this.unlisten = this.props.history.listen((location, action) => {
            this.props.ga.track(location)
        })

        this.props.ga.track(this.props.history.location)
    }

    componentWillUnmount(): void {
        if (this.unlisten) {
            this.unlisten()
        }
    }

    render (): React.ReactNode {
        return null
    }
}

export default withRouter(Tracker)
