import { StoreContainer } from 'react-mobx-store-container'
import { Container as BaseContainer } from 'react-festival/build/store-container'
import { RemoteCatalog, LocaleStore, SimpleCatalog } from 'react-mobx-intl'
import { Store as CookieConsentStore } from 'react-mobx-cookie-consent'
import { GA } from './analytics/ga'

export const Container = (manifestContent: any = {}, env: any = {}): StoreContainer => {
    const container = BaseContainer(manifestContent, env)

    const manifest = container.get('manifest')
    const localeStore = container.get('locale')
    localeStore.addCatalog(new RemoteCatalog('fr', manifest.get('translations/app.ext.fr.json'), ['app.ext']))

    const ga = new GA(env.GA)

    container.addStore('ga', ga)

    const cookieConsentStore = new CookieConsentStore({ cookie: { secure: false }, cookies: env.cookies })

    cookieConsentStore.addService({
        id: 'main',
        needConsent: false,
        type: 'main',
        name: 'Fonctionnement',
        cookies: ['rmcc', 'api-token']
    })

    cookieConsentStore.addService({
        id: 'ga',
        needConsent: true,
        type: 'analytics',
        name: 'Google Analytics',
        cookies: ['_ga', '_gid'],
        onAccept: () => {
            ga.enable()
        },
        onDecline: () => {
            ga.disable()
        }
    })

    container.addStore('cookieConsentStore', cookieConsentStore)

    return container
}
