import * as React from 'react'
import { Dialog as Base, Store } from 'react-mobx-cookie-consent'
import { observer } from 'mobx-react'

export default class Dialog extends Base {

    renderModalHeader (): React.ReactNode {
        return 'Votre choix'
    }

    renderModalBody (): React.ReactNode {
        return 'Ce site utilise des cookies afin de vous faire profiter d\'une navigation optimale'
    }

    renderButtonAcceptAll (): React.ReactNode {
        return <>
            <i className="mdi mdi-check"></i> Tout accepter
        </>
    }

    renderButtonDeclineAll (): React.ReactNode {
        return <>
            <i className="mdi mdi-close"></i> Tout refuser
        </>
    }
}
