import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { RouteManager } from '@festival/route-manager'
import Advertisement from '@festival/page-configuration/advertisement'
import PartnerList from '@festival/partner/list'
import Manifest from '@festival/manifest'
import { Store as CookieConsentStore } from 'react-mobx-cookie-consent'
import { Launcher } from './cookie-consent/launcher'

export interface Props extends React.ComponentProps<'div'>{
    routeManager?: RouteManager
    manifest?: Manifest
    cookieConsentStore?: CookieConsentStore
}

export interface State {

}

export class Side extends React.Component<Props, State> {

    public render (): React.ReactNode {
        const { routeManager, className, manifest } = this.props

        if (!routeManager || !manifest) {
            return null
        }

        const legal = routeManager.generate('other.mentions-legales')

        return <div id="side" className={className}>
            <div className="d-flex justify-content-center mb-2">
                <Advertisement/>
            </div>
            <div id="social-links" className="d-flex flex-wrap justify-content-around">
                <div className="d-flex flex-wrap justify-content-around flex-fill">
                    <a href="https://www.facebook.com/HashtagFestival01" target="_blank" rel="noreferrer" id="facebook">@HashtagFestival01</a>
                    <a href="https://www.ainterexpo.com/" target="_blank" rel="noreferrer" id="website" className="right">ainterexpo.com</a>
                </div>
                <div className="d-flex flex-wrap justify-content-around flex-fill">
                    <a href="https://twitter.com/FestivalHashtag" target="_blank" rel="noreferrer" id="twitter">@FestivalHashtag</a>
                    <a href="https://www.instagram.com/hashtagfestival01/" target="_blank" rel="noreferrer" id="instagram" className="right">@hashtagfestival01</a>
                </div>
            </div>
            <div className="d-flex flex-wrap justify-content-around">
                <div className="border border-primary mb-2">
                    <PartnerList />
                </div>
                <div id="ainterexpo" className="text-center mt-3">
                    <img src={manifest.get('img/ainterexpo.png')} alt="Ainterexpo" className="w-100"/>
                    <small>25 Avenue du Maréchal Juin<br/>01000 Bourg-en-Bresse</small>
                    <br />
                    <small>04.74.22.12.33</small>
                    <br />
                    <a href="https://www.ainterexpo.com" target="_blank" rel="noreferrer" className="ml-2"><small>www.ainterexpo.com</small></a>
                    <br />
                    { legal && (
                        <Link to={legal}>
                            <small>Mentions légales</small>
                        </Link>
                    )}
                </div>
            </div>
            <div className="text-center">
                { this.props.cookieConsentStore && (
                    <Launcher store={this.props.cookieConsentStore}/>
                )}
            </div>
        </div>
    }
}

export default inject('routeManager', 'manifest', 'cookieConsentStore')(observer(Side))
