import * as React from 'react'
import { Launcher as Base } from 'react-mobx-cookie-consent'

export class Launcher extends Base {
    renderContent (): React.ReactNode {
        return (
            <small>
                <i className="mdi mdi-cookie mr-2" />
                Gestion des cookies
            </small>
        )
    }
}
