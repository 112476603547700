import ReactGA from 'react-ga4'
import { Location } from 'history'

export class GA {
    protected _enable: boolean = false

    constructor (key: string, debug: boolean = false) {
        ReactGA.initialize([{
            trackingId: key,
        }])
    }

    public enable (): void {
        this._enable = true
    }

    public disable (): void {
        this._enable = false
    }

    public track (location: Location): void {
        if (this._enable) {
            ReactGA.send({
                hitType: 'pageview',
                page: location.pathname + location.search,
            });
        }
    }
}
