require('../css/app.scss')

import * as React from 'react'
import { hydrate } from 'react-dom'
import Bootstrap from '@festival/bootstrap'
import { BrowserRouter } from 'react-router-dom'
import { Container } from '@festival/store-container'
import { loadableReady } from '@loadable/component'
import Dialog from './cookie-consent/dialog'
import Tracker from './analytics/tracker'

declare global {
    interface Window {
        __INITIAL_STATE__: {
            dataContainer: any
            dataManifest: Record<string, string>
        },
        __ENV__: {
            'ENDPOINT': string
            'API_HOST': string
            'API_ENDPOINT': string
        }
    }
}

const bootstrap = (): void => {
    const element: Element = document.getElementById('app') as Element

    if (element) {
        const container = Container(window.__INITIAL_STATE__.dataManifest, window.__ENV__)
        container.deserialize(window.__INITIAL_STATE__.dataContainer)

        hydrate(
            <BrowserRouter>
                <Bootstrap container={container} />
                <Dialog store={container.get('cookieConsentStore')}/>
                <Tracker ga={container.get('ga')}/>
            </BrowserRouter>,
            element
        )
    }
}

loadableReady(() => bootstrap())
